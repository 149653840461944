let settings = {
    allowed: {
        categories: ['essential'],
    },
};
const categoryCookies = [];
const categories = ['essential', 'stats'];
const settingsCookieName = 'hc_cookie-settings';

const cookieBanner = {
    init() {
        const banner = document.querySelector('.js-cookie-banner');

        if (cookieBanner.getCookie(settingsCookieName) !== null) {
            settings = JSON.parse(cookieBanner.getCookie(settingsCookieName));
        } else {
            banner.classList.remove('hidden');
        }

        const bannerTrigger = document.querySelector('.js-cookie-banner-trigger');
        bannerTrigger.addEventListener('click', (event) => {
            event.preventDefault();
            banner.classList.remove('hidden');
        });

        let activePage = banner.querySelector('.js-cookie-banner-page-home');
        const pageTriggers = banner.querySelectorAll('.js-cookie-banner-page-trigger');
        pageTriggers.forEach((pageTrigger) => {
            const {pageName} = pageTrigger.dataset;
            pageTrigger.addEventListener('click', () => {
                activePage.classList.add('hidden');

                activePage = banner.querySelector(`.js-cookie-banner-page-${pageName}`);
                activePage.classList.remove('hidden');
            });
        });

        const scripts = document.querySelectorAll('script[data-script-cat]');
        scripts.forEach((script) => {
            const {scriptCat} = script.dataset;
            if (typeof scriptCat !== 'undefined' && categories.indexOf(scriptCat) !== -1) {
                categoryCookies[scriptCat] = [];

                const {scriptName, scriptProvider} = script.dataset;
                const {cookieNames, cookieTimes, cookieDesc} = script.dataset;
                const cookies = [];

                cookieNames.split(';').forEach((name, index) => {
                    const time = cookieTimes.split(';')[index];
                    const desc = cookieDesc.split(';')[index];
                    // eslint-disable-next-line object-shorthand
                    const cookie = {name: name, time: time, desc: desc};
                    cookies.push(cookie);

                    categoryCookies[scriptCat].push(cookie.name);
                });

                const categoryEntries = banner.querySelector(`.js-cookie-banner-cat-${scriptCat}-entries`);
                const template = banner.querySelector(`.js-cookie-banner-cat-${scriptCat}-entry-template`);
                cookies.forEach((cookie) => {
                    const newChild = template;
                    newChild.innerHTML = template.innerHTML.replace('%name%', scriptName);
                    newChild.innerHTML = template.innerHTML.replace('%provider%', scriptProvider);
                    newChild.innerHTML = template.innerHTML.replace('%cookie.purpose%', cookie.desc);
                    newChild.innerHTML = template.innerHTML.replace('%cookie.name%', cookie.name);
                    newChild.innerHTML = template.innerHTML.replace('%cookie.time%', cookie.time);

                    categoryEntries.appendChild(newChild);
                    newChild.classList.remove('hidden');
                });

                const allowedCategories = settings.allowed.categories;
                cookieBanner.reloadCheckboxes(scriptCat, banner, allowedCategories);

                if (allowedCategories.indexOf(scriptCat) !== -1 && scriptCat !== categories[0]) {
                    cookieBanner.allowScript(script);
                }

                const catToggle = banner.querySelector(`.js-cookie-banner-cat-${scriptCat}-toggle`);

                catToggle.children.item(0).addEventListener('change', (event) => {
                    if (scriptCat !== categories[0]) {
                        if (allowedCategories.indexOf(scriptCat) === -1 && event.target.checked) {
                            allowedCategories.push(scriptCat);
                        } else if (!event.target.checked) {
                            allowedCategories.splice(allowedCategories.indexOf(scriptCat), 1);
                        }
                    }
                });
            }
        });

        const saveTriggers = banner.querySelectorAll('.js-cookie-banner-save-trigger');
        saveTriggers.forEach((trigger) => {
            trigger.addEventListener('click', event => cookieBanner.saveSelection(event, scripts, banner));
        });

        const saveAllTriggers = banner.querySelectorAll('.js-cookie-banner-save-all-trigger');
        saveAllTriggers.forEach((trigger) => {
            trigger.addEventListener('click', event => cookieBanner.acceptAll(event, scripts, banner));
        });
    },

    saveSelection(event, scripts, banner) {
        event.preventDefault();

        localStorage.clear();

        const settingsJson = JSON.stringify(settings);
        cookieBanner.setCookie(settingsCookieName, settingsJson, 365);

        const allowedCategories = settings.allowed.categories;
        scripts.forEach((script) => {
            const {scriptCat} = script.dataset;

            if (allowedCategories.indexOf(scriptCat) !== -1 && scriptCat !== categories[0]) {
                cookieBanner.allowScript(script);
            }

            const currentCookies = cookieBanner.listCookies();
            currentCookies.forEach((cookie) => {
                if (cookie.name !== settingsCookieName
                    && allowedCategories.indexOf(scriptCat) === -1
                ) {
                    categoryCookies[scriptCat].forEach((cookieRegex) => {
                        const found = cookie.name.match(cookieRegex);
                        if (typeof found[0] !== 'undefined') {
                            cookieBanner.setCookie(found[0], '', 0);
                        }
                    });
                }
            });
        });

        banner.classList.add('hidden');
    },

    acceptAll(event, scripts, banner) {
        event.preventDefault();

        const allowedCategories = settings.allowed.categories;

        scripts.forEach((script) => {
            cookieBanner.allowScript(script);

            const {scriptCat} = script.dataset;
            if (allowedCategories.indexOf(scriptCat) === -1) {
                allowedCategories.push(scriptCat);

                cookieBanner.reloadCheckboxes(scriptCat, banner, allowedCategories);
            }
        });

        const settingsJson = JSON.stringify(settings);
        cookieBanner.setCookie(settingsCookieName, settingsJson, 365);

        banner.classList.add('hidden');
    },

    allowScript(script) {
        const newScript = script;
        newScript.type = 'text/javascript';

        document.head.appendChild(newScript);
    },

    setCookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value};${expires};path=/`;
    },

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }

        return null;
    },

    listCookies() {
        const cookies = [];
        document.cookie.split(';').forEach((cookieString) => {
            const cookieArray = cookieString.split('=');
            // eslint-disable-next-line object-shorthand
            const name = cookieArray[0].trim();
            // eslint-disable-next-line object-shorthand
            const value = cookieArray[1].trim();

            // eslint-disable-next-line object-shorthand
            cookies.push({name: name, value: value});
        });

        return cookies;
    },

    reloadCheckboxes(scriptCat, banner, allowedCategories) {
        const scriptCatIsAllowed = (allowedCategories.indexOf(scriptCat) !== -1);
        if (scriptCatIsAllowed) {
            const catToggle = banner.querySelector(`.js-cookie-banner-cat-${scriptCat}-toggle`);
            catToggle.children.item(0).checked = true;
        }
    },
};

export default cookieBanner;
